/* eslint-disable @typescript-eslint/camelcase */
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import LoginQuery from '../Models/LoginQuery';
import JwtToken from '../Models/JwtToken';
import { userRepository } from '../Repositories/UserRepository';
import { roleRepository } from '../Repositories/RoleRepository';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import SsoError from '../Error/SsoError';
import Users from '../Models/Users';
import Logger from '../Logger/Logger';
import { ErrorCode, ThirtyOneError } from '../Error/ThirtyOneError';
import { appRepository } from '../Repositories/AppRepository';
import LoginResponse from '../Models/LoginResponse';
import HttpService from './HttpService';
import { sessionTokenRepository } from '../Repositories/SessionTokenRepository';
import TokenRefreshQuery from '../Models/TokenRefreshQuery';
import PasswordResetResponse from '../Models/PasswordResetResponse';

const logger = Logger.Create('SsoService');
const MissingOidConfigMessage = 'OID Configuration has not been set!';

/**
 * Handles the SSO operations.
 */
export default class SsoService
{
  /**
   * Login.
   * @param username The username.
   * @param password The password.
   */
  public static async login(username = '', password = ''): Promise<PromiseResponse<object>>
  {
    const { oidConfig } = appRepository;

    if (oidConfig === undefined)
    {
      return {
        success: false,
        reason: MissingOidConfigMessage,
      };
    }

    const url = oidConfig.authenticationUrl;
    const data: LoginQuery = {
      grant_type: oidConfig.grantType,
      client_id: oidConfig.clientId,
      client_secret: oidConfig.clientSecret,
      scope: oidConfig.scope,
      username,
      password,
    };
    const queryString = qs.stringify(data);

    return HttpService.postForm<LoginResponse>(url, queryString)
      .then(SsoService.handleLoginSuccess)
      .catch(SsoService.handleLoginFailure);
  }

  /**
   * Refresh token.
   * @param username The username.
   * @param password The password.
   */
  public static async refreshToken(): Promise<PromiseResponse<object>>
  {
    const { oidConfig } = appRepository;

    if (oidConfig === undefined)
    {
      return {
        success: false,
        reason: MissingOidConfigMessage,
      };
    }

    // If we use client credentials, we won't have a refresh token, so just
    // login again.
    if (oidConfig.grantType === 'client_credentials')
    {
      return SsoService.login();
    }

    // Make the token refresh request.
    const url = oidConfig.authenticationUrl;
    const data: TokenRefreshQuery = {
      grant_type: 'refresh_token',
      client_id: oidConfig.clientId,
      client_secret: oidConfig.clientSecret,
      scope: oidConfig.scope,
      refresh_token: sessionTokenRepository.refreshToken,
    };
    const queryString = qs.stringify(data);

    return HttpService.postForm<LoginResponse>(url, queryString)
      .then(SsoService.handleLoginSuccess)
      .catch(SsoService.handleLoginFailure);
  }

  /**
   * Change password
   * @param currentPassword The current password.
   * @param newPassword The new password.
   */
  public static async changePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<PromiseResponse<object>>
  {
    const { oidConfig } = appRepository;

    if (oidConfig === undefined)
    {
      return {
        success: false,
        reason: MissingOidConfigMessage,
      };
    }

    const url = oidConfig.authenticationUrl.replace('connect/token', 'Account/ChangePassword');
    const body = JSON.stringify({
      currentPassword,
      newPassword,
    });

    return SsoService.makeRequestWithRefresh(async () =>
    {
      const token = await sessionTokenRepository.getJwtToken();
      return HttpService.put<object>(url, body, token);
    })
      .then(() =>
      {
        const promiseResponse: PromiseResponse<object> = {
          success: true,
          reason: '',
        };

        return promiseResponse;
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to change password due to the error: ${error.message}`;

        const promiseResponse: PromiseResponse<object> = {
          success: false,
          reason: message,
        };

        logger.error(message);
        return promiseResponse;
      });
  }

  /**
   * Get all users.
   */
  public static async getAllUsers(): Promise<PromiseResponse<object>>
  {
    const { oidConfig } = appRepository;

    if (oidConfig === undefined)
    {
      return {
        success: false,
        reason: MissingOidConfigMessage,
      };
    }

    const url = oidConfig.authenticationUrl.replace('connect/token', 'Account');

    return SsoService.makeRequestWithRefresh(async () =>
    {
      const token = await sessionTokenRepository.getJwtToken();
      return HttpService.get<Users[]>(url, token);
    })
      .then((users: Users[]) =>
      {
        const promiseResponse: PromiseResponse<object> = {
          success: true,
          reason: '',
        };

        // Update the repository.
        userRepository.updateAllUsers(users);

        return promiseResponse;
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to get all users due to the error: ${error.message}`;

        const promiseResponse: PromiseResponse<object> = {
          success: false,
          reason: message,
        };

        logger.error(message);
        return promiseResponse;
      });
  }

  /**
   * Reset password for userId.
   * @param userId The user id.
   */
  public static async resetPassword(userId: string): Promise<PromiseResponse<PasswordResetResponse>>
  {
    const { oidConfig } = appRepository;

    if (oidConfig === undefined)
    {
      return {
        success: false,
        reason: MissingOidConfigMessage,
      };
    }

    const url = oidConfig.authenticationUrl.replace('connect/token', 'Account/ResetPassword');
    const body = JSON.stringify({
      userId,
    });

    return SsoService.makeRequestWithRefresh(async () =>
    {
      const token = await sessionTokenRepository.getJwtToken();
      return HttpService.put<PasswordResetResponse>(url, body, token);
    })
      .then((response: PasswordResetResponse) =>
      {
        const promiseResponse: PromiseResponse<PasswordResetResponse> = {
          success: true,
          reason: '',
          data: response,
        };

        return promiseResponse;
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = error.message === null ? 'Failed to reset password.' : error.message.split('"').join('');

        const promiseResponse: PromiseResponse<PasswordResetResponse> = {
          success: false,
          reason: message,
        };

        logger.error(message);
        return promiseResponse;
      });
  }

  private static async makeRequestWithRefresh<T>(request: () => Promise<T>)
  {
    try
    {
      // Make the first request.
      return await request();
    }
    catch (error)
    {
      const thirty1Error = error as ThirtyOneError;

      // If the error is not unauthorised, continue as normal.
      if (thirty1Error.errorCode !== ErrorCode.Unauthorised)
      {
        return Promise.reject(error);
      }
    }

    // Refresh the token.
    logger.info('Refreshing token...');
    await SsoService.refreshToken();

    try
    {
      // Make the second request attempt.
      return await request();
    }
    catch (error)
    {
      const thirty1Error = error as ThirtyOneError;

      // If the error is not unauthorised, continue as normal.
      if (thirty1Error.errorCode !== ErrorCode.Unauthorised)
      {
        return Promise.reject(error);
      }

      logger.info('Clearing token repository...');
      sessionTokenRepository.clear();
      return Promise.reject(error);
    }
  }

  private static async handleLoginSuccess(response: LoginResponse): Promise<PromiseResponse<object>>
  {
    const token = response.access_token;
    const refreshToken = response.refresh_token;

    // Save the tokens.
    await sessionTokenRepository.saveJwtToken(token);
    sessionTokenRepository.refreshToken = refreshToken;

    try
    {
      // Decode the JWT and update the role.
      const decodedJwt: JwtToken = jwt_decode<JwtToken>(token);
      if (decodedJwt.password_reset_required)
      {
        return PromiseFail('password_reset_required');
      }

      await userRepository.updateCurrentUser({
        id: decodedJwt.sub,
        roles: decodedJwt.role,
        userName: decodedJwt.name,
      });

      await roleRepository.updateRole(decodedJwt.role);
      roleRepository.load();
    }
    catch (error)
    {
      const message = 'Failed to decode the given token.';
      logger.error(message);
      return PromiseFail(message);
    }

    return PromiseSuccess();
  }

  private static async handleLoginFailure(error: ThirtyOneError): Promise<PromiseResponse<object>>
  {
    const message = `Failed to login due to the error: ${error.message}`;

    let ssoError: SsoError = {
      error: error.message,
      error_description: error.message,
      error_uri: '',
    };

    try
    {
      ssoError = JSON.parse(error.message);
    }
    catch (parseError)
    {
      ssoError = {
        error: error.message,
        error_description: error.message,
        error_uri: '',
      };
    }

    logger.error(message);
    return PromiseFail(ssoError.error_description);
  }
}

export const loginService = new SsoService();
