import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import Fonts from '../../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../../Domain/Repositories/ResponsiveScreenRepository';
import ModalViewBase from '../ModalViewBase';
import ModalType from '../../../../Domain/Types/ModalType';
import { Colors } from '../../../../Config/Colors';
import { BaseModalType, modalController } from '../ModalController';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import AreaService from '../../../../Domain/Services/AreaService';
import { areaRepository } from '../../../../Domain/Repositories/AreaRepository';
import CreateAreaModalData from '../../../../Domain/Models/CreateAreaModalData';

interface CreateAreaModalProps
{
  /**
   * The maximum characters.
   */
  maxCharacters?: number;
}

/**
 * The Create Area Modal.
 * @returns JSX.Element.
 */
export default function CreateAreaModal({
  maxCharacters = 50,
}: CreateAreaModalProps): JSX.Element
{
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = (): void =>
  {
    modalController.hide();
  };

  const onNameUpdated = (updatedName: string): void =>
  {
    const truncated = updatedName.substring(0, maxCharacters);
    setName(truncated);
  };

  const onSave = async (): Promise<void> =>
  {
    if (name.trim() === '')
    {
      showFailedModal('You must enter an area name to proceed!');
      return;
    }

    // Check for duplicates
    const areaNames = areaRepository.getAll().map((x) => x.name.toLowerCase());

    if (areaNames.includes(name.toLowerCase()))
    {
      showFailedModal('An area with this name already exists!\nTry again with another name.');
      return;
    }

    setLoading(true);
    const response = await AreaService.create(name);
    setLoading(false);

    if (response.success)
    {
      closeModal();

      const data = modalController.activeModal.data as CreateAreaModalData;

      if (data !== undefined && data.onSuccess !== undefined)
      {
        data.onSuccess();
      }
      return;
    }

    showFailedModal(response.reason);
  };

  const showFailedModal = (reason: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: (): void => modalController.showPrevious(),
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const reset = (): void =>
  {
    setName('');
    setLoading(false);
  };

  const styles = useMemo(() => StyleSheet.create({
    content: {
      height: RFPercentage(24),
      width: undefined,
      aspectRatio: 1.8,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
    },
    container: {
      height: '100%',
      width: '100%',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'left',
      fontSize: RFValue(11),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
      textTransform: 'uppercase',
    },
    nameInputView: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      height: '30%',
      width: '50%',
      backgroundColor: Colors.BLACK(0.15),
      color: 'white',
      paddingHorizontal: '5%',
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
    },
    message: {
      color: Colors.APP.red,
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
      paddingTop: '1%',
    },
    closeView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    button: {
      width: '30%',
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5%',
      marginHorizontal: '5%',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(11),
      color: 'white',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <ModalViewBase
      onModalHidden={reset}
      modalType={ModalType.CreateAreaModal}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.content}>
        <View style={styles.container}>
          <View style={styles.titleView}>
            <Text style={styles.titleText}>
              Add Area
            </Text>
          </View>

          <View style={styles.nameInputView}>
            <TextInput
              style={styles.input}
              keyboardType="email-address"
              placeholder="Enter Area Name"
              autoCompleteType="off"
              placeholderTextColor="white"
              onChangeText={onNameUpdated}
              value={name}
              maxLength={maxCharacters}
            />
          </View>

          {!loading ? (
            <View style={styles.closeView}>
              <TouchableOpacity
                style={styles.button}
                disabled={name.trim() === ''}
                onPress={onSave}
              >
                <LinearGradient
                  colors={name.trim() === '' ? Colors.LOGIN().inactive : Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Save Changes
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={closeModal}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Cancel
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.closeView}>
              <ActivityIndicator color={Colors.APP.teal} size="large" />
            </View>
          )}
        </View>
      </View>
    </ModalViewBase>
  ));
}
