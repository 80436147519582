/* eslint-disable import/prefer-default-export */

interface LoginColor
{
  active: string[];
  inactive: string[];
}

const APP = {
  background: ['black', '#0A0050', '#0A0050', '#0A0050'],
  red: 'rgb(196, 1, 58)',
  green: 'rgb(82, 146, 75)',
  blue: 'rgb(59, 100, 148)',
  darkBlue: '#0A0050',
  teal: 'rgb(112, 197, 220)',
  gold: 'rgb(213, 187, 120)',
};

const PRIMARY = (opacity = 1): string => `rgba(36, 36, 36, ${opacity})`;

const BLACK = (opacity = 1): string => `rgba(0, 0, 0, ${opacity})`;

const LOGIN = (): LoginColor => ({
  active: [APP.teal, APP.teal],
  inactive: [BLACK(0.25), BLACK(0.25)],
});

const DROPDOWN = {
  icon: 'white',
  border: 'transparent',
  unselected: '#393838',
  selected: APP.teal,
};

/**
 * The Cube Colors.
 */
export const Colors = {
  LOGIN,
  DROPDOWN,
  BLACK,
  PRIMARY,
  APP,
};
