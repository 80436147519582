import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { screenRepository } from '../../../Domain/Repositories/ScreenRepository';

interface ScreenListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  screenId: string;

  /**
   * Callback when the Select Area was pressed.
   * @param id The selected id.
   */
  onSelectAreaPressed?: (id: string) => void;

  /**
   * Item index.
   */
  index?: number;
}

/**
 * Renders the Screen List Item.
 */
export default function ScreenListItem({
  itemHeight = RFValue(50),
  screenId,
  onSelectAreaPressed,
  index = 0,
}: ScreenListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      flexDirection: 'row',
      backgroundColor: index % 2 !== 0 ? Colors.BLACK(0.2) : undefined,
    },
    labelContainer: {
      flex: 0.7,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(17),
    },
    buttonContainer: {
      flex: 0.3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: RFValue(11),
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
      marginRight: RFValue(4),
    },
    buttonLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
    },
  });

  const getScreenName = (): string =>
  {
    const screen = screenRepository.getById(screenId);
    return screen === undefined ? '' : screen.name;
  };

  const selectAreaPressed = (): void =>
  {
    if (onSelectAreaPressed !== undefined)
    {
      onSelectAreaPressed(screenId);
    }
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text style={styles.label}>
          {getScreenName()}
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={selectAreaPressed}
        >
          <View style={styles.buttonLabelContainer}>
            <Text style={styles.buttonLabel}>
              Edit Area
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  ));
}
