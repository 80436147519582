/**
 * The available screen types.
 */
enum ScreenTypes
{
  Login = '',
  ResetPassword = 'ResetPassword',
  ScreenList = 'ScreenList',
  VenueAreas = 'VenueAreas',
  ChannelControl = 'ChannelControl',
  ChannelManagement = 'ChannelManagement',
}

export default ScreenTypes;
