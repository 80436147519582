import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-native';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import PartyOverride from './components/PartyOverride';
import { Colors } from '../../Config/Colors';
import ChannelListItem from './components/ChannelListItem';
import Fonts from '../../Domain/Types/Fonts';
import { screenRepository } from '../../Domain/Repositories/ScreenRepository';
import useNavigation from '../../Domain/Hooks/useNavigation';
import ScreenResponse from '../../Domain/Models/Responses/ScreenResponse';
import IConfirmationDialogModel from '../../Domain/Models/IConfirmationDialogModel';
import ScreenService from '../../Domain/Services/ScreenService';
import SetChannelRequest from '../../Domain/Models/Requests/SetChannelRequest';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import { channelRepository } from '../../Domain/Repositories/ChannelRepository';
import RefreshScreenRequest from '../../Domain/Models/Requests/RefreshScreenRequest';

const EnablePartyOverride = false;
const ItemSeparation = 10;
const HorizontalSeparation = RFValue(ItemSeparation * 2); // Both left and right.

interface ChannelControlScreenProps
{
  screenId: string;
}

/**
 * Renders the Channel Control screen.
 */
export default function ChannelControlScreen(props: RouteComponentProps<{},
  never, ChannelControlScreenProps>): JSX.Element
{
  const { navigateToScreen } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState('');
  const [channels, setChannels] = useState<string[]>([]);
  const [itemWidth, setItemWidth] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() =>
  {
    setChannels([]);
    setScreen('');

    const { location } = props;
    if (location.state === undefined)
    {
      return;
    }

    const { screenId } = location.state;
    const repoScreen = screenRepository.getById(screenId);

    if (repoScreen === undefined)
    {
      return;
    }

    const allChannels = channelRepository
      .getAll()
      .slice()
      .sort((a, b) =>
      {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB)
        {
          return 1;
        }

        if (nameA < nameB)
        {
          return -1;
        }

        return 0;
      })
      .map((x) => x.id)
      .filter((x) => repoScreen.channelIds.includes(x));

    const activeChannel = repoScreen.channelId;

    // Combine with the active channel.
    // Ensuring that the active channel is always the first element.
    if (activeChannel !== null)
    {
      const index = allChannels.indexOf(activeChannel);

      // Remove existing occurrence of activeChannel.
      if (index !== -1)
      {
        allChannels.splice(index, 1);
      }

      // Set the active channel as the first element.
      allChannels.unshift(activeChannel);
    }

    setScreen(screenId);
    setChannels(allChannels);
  }, [refresh, loading]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    partyOverrideContainer: {
      flex: 2,
      width: '97%',
      backgroundColor: Colors.BLACK(0.2),
    },
    topBarContainer: {
      flex: 0.9,
      width: '100%',
      backgroundColor: Colors.BLACK(0.4),
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    activityIndicator: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleLabelContainer: {
      flex: 0.76,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleParentContainer: {
      height: RFValue(13),
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    titleContentContainer: {
      height: '100%',
      width: '96%',
      justifyContent: 'center',
      flexDirection: 'row-reverse',
    },
    countTitleContainer: {
      height: '100%',
      flex: 3,
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.red,
    },
    countLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(6),
    },
    screenLabelContainer: {
      height: '100%',
      flex: 11,
      justifyContent: 'center',
    },
    screenLabel: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      width: '90%',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    optionsContainer: {
      flex: 0.24,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    manageButton: {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      height: RFValue(12),
      width: '100%',
      justifyContent: 'center',
      borderLeftWidth: RFValue(1.5),
      borderLeftColor: Colors.APP.teal,
    },
    label: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      width: '95%',
      paddingLeft: RFValue(5),
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    listContainer: {
      flex: 8,
      width: '97%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    flatList: {
      width: '100%',
      height: '100%',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    redirectButton: {
      height: RFValue(25),
      width: RFValue(90),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP.teal,
      marginTop: '2%',
    },
    redirectButtonLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
  });

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    const { width } = event.nativeEvent.layout;
    setItemWidth((width / 3) - HorizontalSeparation);
  };

  /**
   * Returns the column count.
   * If the column is specified as 3 but we only have 1 data,
   * the flat list will attempt to read data 2 and 3 because of 3 columns.
   * @param channelCount The channel count.
   * @returns Column.
   */
  const getColumnCount = (channelCount: number): number =>
  {
    if (channelCount <= 2)
    {
      return channelCount;
    }

    return 3;
  };

  const getActiveStatus = (currentScreen: ScreenResponse, currentChannelId: string): boolean =>
  {
    if (currentScreen.channelId === null)
    {
      return false;
    }

    return currentScreen.channelId === currentChannelId;
  };

  const getContent = (): JSX.Element =>
  {
    const repoScreen = screenRepository.getById(screen);
    if (loading)
    {
      return (
        <ActivityIndicator color={Colors.APP.teal} size="large" />
      );
    }

    if (repoScreen === undefined)
    {
      return (
        <>
          <Text style={styles.noDataAvailableLabel}>
            No screen selected
          </Text>

          <TouchableOpacity
            style={styles.redirectButton}
            onPress={(): void => navigateToScreen(ScreenTypes.VenueAreas)}
          >
            <Text style={styles.redirectButtonLabel}>
              Select
            </Text>
          </TouchableOpacity>
        </>
      );
    }

    if (channels.length !== 0)
    {
      return (
        <FlatList<string>
          style={styles.flatList}
          data={channels}
          horizontal={false}
          onLayout={onLayout}
          numColumns={getColumnCount(channels.length)}
          renderItem={({ item }): JSX.Element => (
            <ChannelListItem
              channelId={item}
              isActive={getActiveStatus(repoScreen, item)}
              onSetChannelPressed={(): void => onSetChannelPressed(item)}
              itemWidth={itemWidth}
              itemSeparation={RFValue(ItemSeparation)}
            />
          )}
          keyExtractor={(item): string => JSON.stringify(item)}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      );
    }

    return (
      <Text style={styles.noDataAvailableLabel}>
        No channels available.
      </Text>
    );
  };

  const onSetChannelPressed = (channelId: string): void =>
  {
    const repoScreen = screenRepository.getById(screen);
    let data: IConfirmationDialogModel;
    if (repoScreen === undefined || channelId !== repoScreen.channelId)
    {
      data = {
        message: 'Are you sure you want to\nset the channel for this screen?',
        title: 'Info',
        positiveAction: async () =>
        {
          await setChannel(channelId);
        },
        negativeAction: (): void => modalController.hide(),
      };
    }
    else
    {
      data = {
        message: 'Are you sure you want to\nrefresh the screen?',
        title: 'Info',
        positiveAction: async () =>
        {
          await refreshScreen();
        },
        negativeAction: (): void => modalController.hide(),
      };
    }

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data,
    });
  };

  const showNotification = (message = '', error = false): void =>
  {
    const data: INotificationModalData = {
      title: error ? 'Error' : 'Info',
      message: error ? message : 'Successfully sent the request to set the channel!',
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data,
    });
  };

  const setChannel = async (channelId: string): Promise<void> =>
  {
    setLoading(true);
    const request: SetChannelRequest = {
      screenId: screen,
      channelId,
    };

    const response = await ScreenService.setChannel(request);
    setLoading(false);

    if (response.success)
    {
      setRefresh(!refresh);
      showNotification();
      return;
    }

    showNotification(response.reason, true);
  };

  const refreshScreen = async (): Promise<void> =>
  {
    setLoading(true);
    const request: RefreshScreenRequest = {
      screenId: screen,
    };

    const response = await ScreenService.refreshScreen(request);
    setLoading(false);

    if (response.success)
    {
      setRefresh(!refresh);
      showNotification('Screen successfully refreshed.');
      return;
    }

    showNotification(response.reason, true);
  };

  const getTitleContent = (): JSX.Element =>
  {
    if (loading)
    {
      return (
        <View style={styles.activityIndicator}>
          <ActivityIndicator color={Colors.APP.teal} size="large" />
        </View>
      );
    }

    if (screen.trim() === '')
    {
      return (<></>);
    }

    const repoScreen = screenRepository.getById(screen);
    const title = repoScreen === undefined ? '' : repoScreen.name;

    return (
      <View style={styles.titleParentContainer}>
        <View style={styles.titleContentContainer}>
          <View style={styles.countTitleContainer}>
            <Text style={styles.countLabel}>
              {`${channels.length} / ${channelRepository.all.length}`}
            </Text>
          </View>
          <View style={styles.screenLabelContainer}>
            <Text style={styles.screenLabel}>
              {title}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const onManageChannelsPressed = (): void =>
  {
    navigateToScreen(ScreenTypes.ChannelManagement, {
      screenId: screen,
    });
  };

  const onBackButtonPressed = (): void =>
  {
    const repoScreen = screenRepository.getById(screen);
    if (repoScreen === undefined)
    {
      navigateToScreen(ScreenTypes.VenueAreas, {
        areaId: '',
      });
      return;
    }

    navigateToScreen(ScreenTypes.VenueAreas, {
      areaId: repoScreen.areaId,
    });
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.ChannelControl}
      onSyncProgressChanged={setLoading}
      leftButtonAction={onBackButtonPressed}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.topBarContainer}>
            <View style={styles.titleLabelContainer}>
              {getTitleContent()}
            </View>

            <View style={styles.optionsContainer}>
              <TouchableOpacity
                style={[styles.manageButton, {
                  opacity: screen.trim() === '' ? 0.5 : 1,
                }]}
                disabled={screen.trim() === ''}
                onPress={onManageChannelsPressed}
              >
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>
                    Manage Channels
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          {EnablePartyOverride && (
            <View style={styles.partyOverrideContainer}>
              <PartyOverride />
            </View>
          )}

          <View style={styles.listContainer}>
            {getContent()}
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
