import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import AreaResponse from '../Models/Responses/AreaResponse';
import { areaRepository } from '../Repositories/AreaRepository';
import ApiService from './ApiService';

const logger = Logger.Create('AreaService');

/**
 * Handles area operations.
 */
export default class AreaService
{
  /**
   * Get the available areas.
   * @returns Promise.
   */
  public static async get(): Promise<PromiseResponse<AreaResponse[]>>
  {
    const url = '/api/Area';

    return ApiService.get<AreaResponse[]>(url)
      .then((response: AreaResponse[]) =>
      {
        areaRepository.replace(response);
        return PromiseSuccess<AreaResponse[]>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to get area: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Create a new area.
   * @returns Promise.
   */
  public static async create(name: string): Promise<PromiseResponse<AreaResponse>>
  {
    const url = '/api/Area';
    const body = {
      name,
    };

    return ApiService.post<AreaResponse>(url, body)
      .then((response: AreaResponse) =>
      {
        areaRepository.addOrUpdate(response);
        return PromiseSuccess<AreaResponse>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to create area: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }
}
