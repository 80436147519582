import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import ApiService from './ApiService';

const logger = Logger.Create('SystemService');

/**
 * Handles system operations.
 */
export default class SystemService
{
  /**
   * Syncs the screens and areas in the server.
   * @returns Promise.
   */
  public static async sync(): Promise<PromiseResponse>
  {
    const url = '/api/System/Sync';

    return ApiService.post(url)
      .then(() => PromiseSuccess())
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to sync: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }
}
