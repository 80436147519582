import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { sessionTokenRepository } from '../../Domain/Repositories/SessionTokenRepository';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import useNavigation from '../../Domain/Hooks/useNavigation';

/**
 * Login redirect.
 * @returns JSX.Element.
 */
export default function LoginRedirect(): JSX.Element
{
  const { navigateToScreen } = useNavigation();

  const [loading, setLoading] = useState(true);

  useEffect(() =>
  {
    setLoading(true);
    sessionTokenRepository.getJwtToken()
      .then(() => setLoading(false));
  }, []);

  useEffect(() =>
  {
    if (sessionTokenRepository.token.trim() === '' && !loading)
    {
      navigateToScreen(ScreenTypes.Login);
    }
  });

  const redirect = (): JSX.Element =>
  {
    if (sessionTokenRepository.token.trim() === '')
    {
    }

    return (<></>);
  };

  return useObserver(() => (
    <>
      {redirect()}
    </>
  ));
}
