import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { useObserver } from 'mobx-react-lite';
import { BaseModalType, modalController } from '../ModalController';
import ModalViewBase from '../ModalViewBase';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import { Colors } from '../../../../Config/Colors';
import Fonts from '../../../../Domain/Types/Fonts';

/**
 * Simple Notification Modal.
 * @returns JSX.Element.
 */
export default function SimpleNotificationModal({
  title = '',
  message = '',
  onConfirmButtonPressed,
  disableCloseOnConfirm = false,
}: INotificationModalData): JSX.Element
{
  const getData = (): INotificationModalData =>
  {
    const data = modalController.activeModal.data as INotificationModalData;
    if (data === undefined)
    {
      return {
        title,
        message,
      };
    }

    return data;
  };

  const onDecisionButtonPressed = (): void =>
  {
    const data = modalController.activeModal.data as INotificationModalData;

    if (data.onConfirmButtonPressed !== undefined)
    {
      data.onConfirmButtonPressed();
    }

    if (onConfirmButtonPressed !== undefined)
    {
      onConfirmButtonPressed();
    }

    if (data.disableCloseOnConfirm || disableCloseOnConfirm)
    {
      return;
    }

    modalController.hide();
  };

  const styles = StyleSheet.create({
    container: {
      height: RFPercentage(20),
      width: undefined,
      aspectRatio: 2,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.red,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
    },
    titleText: {
      textAlign: 'center',
      fontSize: RFValue(15),
      fontFamily: Fonts.JudgeMedium,
      textTransform: 'uppercase',
      color: Colors.APP.red,
    },
    contentView: {
      flex: 0.55,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '80%',
    },
    decisionButtonView: {
      flex: 0.15,
      justifyContent: 'center',
      alignItems: 'center',
      width: '45%',
    },
    decisionButtonContent: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    decisionButton: {
      width: '45%',
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      marginTop: '1.5%',
      shadowColor: 'black',
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 0.4,
      shadowRadius: 20,
    },
    decisionButtonText: {
      textAlign: 'center',
      fontSize: RFValue(8),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textTransform: 'uppercase',
    },
    errorMessageView: {
      flex: 0.1,
      width: '100%',
      justifyContent: 'flex-start',
    },
    errorText: {
      textAlign: 'center',
      fontSize: RFValue(6),
      fontFamily: Fonts.PoppinsMedium,
      color: Colors.APP.red,
    },
  });

  return useObserver(() => (
    <ModalViewBase
      modalType={BaseModalType.SimpleNotification}
      onModalIntentionallyClosed={onDecisionButtonPressed}
    >
      <View style={styles.container}>
        <View style={styles.titleView}>
          <Text style={styles.titleText}>
            {getData().title}
          </Text>
        </View>

        <View style={styles.contentView}>
          <Text style={styles.contentText}>
            {getData().message}
          </Text>
        </View>

        <View style={styles.decisionButtonView}>
          <TouchableOpacity
            onPress={onDecisionButtonPressed}
            style={styles.decisionButton}
          >
            <LinearGradient
              colors={Colors.LOGIN().active}
              style={styles.decisionButtonContent}
            >
              <Text style={styles.decisionButtonText}>
                Ok
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </View>
    </ModalViewBase>
  ));
}
