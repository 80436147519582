/* eslint-disable class-methods-use-this */
import { observable, decorate } from 'mobx';
import LocalStorageService from '../Services/LocalStorageService';

/**
 * Repository containing JWT access token.
 */
export class SessionTokenRepository
{
  /**
    * The JWT token.
    */
  public token = '';

  /**
    * The JWT refresh token.
    */
  public refreshToken = '';

  /**
   * The websocket ticket.
   */
  public ticket = '';

  protected tokenStorage: LocalStorageService<string> = new LocalStorageService<string>('remoteJwtToken');

  protected ticketStorage: LocalStorageService<string> = new LocalStorageService<string>('remoteWsTicket');

  /**
   * Clears all the token.
   */
  public clear(): void
  {
    this.ticket = '';
    this.token = '';
    this.refreshToken = '';
    this.tokenStorage.clear();
    this.ticketStorage.clear();
  }

  /**
   * Save the JWT Token.
   * @param jwtToken The JWT Token.
   */
  public async saveJwtToken(jwtToken: string): Promise<void>
  {
    this.token = jwtToken;
    await this.tokenStorage.save(jwtToken);
  }

  /**
   * Saves the Websocket ticket.
   * @param wsTicket The WebSocket Ticket.
   */
  public async saveWsTicket(wsTicket: string): Promise<void>
  {
    this.ticket = wsTicket;
    await this.ticketStorage.save(wsTicket);
  }

  /**
   * Retrieves the JWT Token.
   * @returns Jwt Token.
   */
  public async getJwtToken(): Promise<string>
  {
    if (this.token.trim() === '')
    {
      const value = await this.tokenStorage.load();
      this.token = value ?? '';
    }

    return this.token;
  }

  /**
   * Retrieves the Websocket Ticket.
   * @returns Websocket Ticket.
   */
  public async getWsTicket(): Promise<string>
  {
    if (this.ticket.trim() === '')
    {
      const value = await this.ticketStorage.load();
      this.ticket = value ?? '';
    }

    return this.ticket;
  }
}

decorate(SessionTokenRepository, {
  token: observable,
  ticket: observable,
});

export const sessionTokenRepository = new SessionTokenRepository();
