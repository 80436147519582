/* eslint-disable no-new */
import * as Font from 'expo-font';
import Logger from '../Logger/Logger';

const logger = Logger.Create('FontHandler');

/**
 * Font Handler
 */
export default class FontHandler
{
  /**
   * Loads all the required fonts. This should be called at highest component
   * during the initialisation phase. In this case, App.tsx.
   */
  public static async initialise(): Promise<boolean>
  {
    await Font.loadAsync({
      BebasNeue: {
        uri: require('../../Fonts/BebasNue/BebasNeue-Regular.ttf'),
      },
      PoppinsExtraBoldItalic: {
        uri: require('../../Fonts/Poppins/PoppinsExtraBoldItalic.ttf'),
      },
      PoppinsBlack: {
        uri: require('../../Fonts/Poppins/PoppinsBlack.ttf'),
      },
      PoppinsBlackItalic: {
        uri: require('../../Fonts/Poppins/PoppinsBlackItalic.ttf'),
      },
      PoppinsBold: {
        uri: require('../../Fonts/Poppins/PoppinsBold.ttf'),
      },
      PoppinsBoldItalic: {
        uri: require('../../Fonts/Poppins/PoppinsBoldItalic.ttf'),
      },
      PoppinsExtraBold: {
        uri: require('../../Fonts/Poppins/PoppinsExtraBold.ttf'),
      },
      PoppinsExtraLight: {
        uri: require('../../Fonts/Poppins/PoppinsExtraLight.ttf'),
      },
      PoppinsExtraLightItalic: {
        uri: require('../../Fonts/Poppins/PoppinsExtraLightItalic.ttf'),
      },
      PoppinsItalic: {
        uri: require('../../Fonts/Poppins/PoppinsItalic.ttf'),
      },
      PoppinsLight: {
        uri: require('../../Fonts/Poppins/PoppinsLight.ttf'),
      },
      PoppinsLightItalic: {
        uri: require('../../Fonts/Poppins/PoppinsLightItalic.ttf'),
      },
      PoppinsMedium: {
        uri: require('../../Fonts/Poppins/PoppinsMedium.ttf'),
      },
      PoppinsMediumItalic: {
        uri: require('../../Fonts/Poppins/PoppinsMediumItalic.ttf'),
      },
      PoppinsRegular: {
        uri: require('../../Fonts/Poppins/PoppinsRegular.ttf'),
      },
      PoppinsSemiBold: {
        uri: require('../../Fonts/Poppins/PoppinsSemiBold.ttf'),
      },
      PoppinsSemiBoldItalic: {
        uri: require('../../Fonts/Poppins/PoppinsSemiBoldItalic.ttf'),
      },
      PoppinsThin: {
        uri: require('../../Fonts/Poppins/PoppinsThin.ttf'),
      },
      PoppinsThinItalic: {
        uri: require('../../Fonts/Poppins/PoppinsThinItalic.ttf'),
      },
      MontserratBlack: {
        uri: require('../../Fonts/Montserrat/MontserratBlack.ttf'),
      },
      MontserratBlackItalic: {
        uri: require('../../Fonts/Montserrat/MontserratBlackItalic.ttf'),
      },
      MontserratBold: {
        uri: require('../../Fonts/Montserrat/MontserratBold.ttf'),
      },
      MontserratBoldItalic: {
        uri: require('../../Fonts/Montserrat/MontserratBoldItalic.ttf'),
      },
      MontserratExtraBold: {
        uri: require('../../Fonts/Montserrat/MontserratExtraBold.ttf'),
      },
      MontserratExtraBoldItalic: {
        uri: require('../../Fonts/Montserrat/MontserratExtraBoldItalic.ttf'),
      },
      MontserratExtraLight: {
        uri: require('../../Fonts/Montserrat/MontserratExtraLight.ttf'),
      },
      MontserratExtraLightItalic: {
        uri: require('../../Fonts/Montserrat/MontserratExtraLightItalic.ttf'),
      },
      MontserratItalic: {
        uri: require('../../Fonts/Montserrat/MontserratItalic.ttf'),
      },
      MontserratLight: {
        uri: require('../../Fonts/Montserrat/MontserratLight.ttf'),
      },
      MontserratLightItalic: {
        uri: require('../../Fonts/Montserrat/MontserratLightItalic.ttf'),
      },
      MontserratMedium: {
        uri: require('../../Fonts/Montserrat/MontserratMedium.ttf'),
      },
      MontserratMediumItalic: {
        uri: require('../../Fonts/Montserrat/MontserratMediumItalic.ttf'),
      },
      MontserratRegular: {
        uri: require('../../Fonts/Montserrat/MontserratRegular.ttf'),
      },
      MontserratSemiBold: {
        uri: require('../../Fonts/Montserrat/MontserratSemiBold.ttf'),
      },
      MontserratSemiBoldItalic: {
        uri: require('../../Fonts/Montserrat/MontserratSemiBoldItalic.ttf'),
      },
      MontserratThin: {
        uri: require('../../Fonts/Montserrat/MontserratThin.ttf'),
      },
      MontserratThinItalic: {
        uri: require('../../Fonts/Montserrat/MontserratThinItalic.ttf'),
      },
      DDINItalic: {
        uri: require('../../Fonts/Din/DDINItalic.otf'),
      },
      DDIN: {
        uri: require('../../Fonts/Din/DDIN.otf'),
      },
      DDINBold: {
        uri: require('../../Fonts/Din/DDINBold.otf'),
      },
      DDINCondensedBold: {
        uri: require('../../Fonts/Din/DDINCondensedBold.otf'),
      },
      DDINCondensed: {
        uri: require('../../Fonts/Din/DDINCondensed.otf'),
      },
      DDINExp: {
        uri: require('../../Fonts/Din/DDINExp.otf'),
      },
      DDINExpBold: {
        uri: require('../../Fonts/Din/DDINExpBold.otf'),
      },
      DDINExpItalic: {
        uri: require('../../Fonts/Din/DDINExpItalic.otf'),
      },
      TheCube2020Regular: {
        uri: require('../../Fonts/TheCube2020/TheCube2020Regular.otf'),
      },
      TheCube2020Outline: {
        uri: require('../../Fonts/TheCube2020/TheCube2020Outline.otf'),
      },
      JudgeMedium: {
        uri: require('../../Fonts/Judge/JudgeMedium.otf'),
      },
      LondrinaOutlineRegular: {
        uri: require('../../Fonts/Londrina/LondrinaOutlineRegular.ttf'),
      },
    })
      .then(() =>
      {
        logger.info('Successfully loaded all the fonts required!');
      })
      .catch((error) =>
      {
        logger.error(`Failed to load the requested font because of the error ${error}. The application may not display the content correctly.`);
      });

    return true;
  }
}
