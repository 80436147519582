/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import React from 'react';
import GenericConfirmationModal from './GenericConfirmationModal/GenericConfirmationModal';
import SimpleNotificationModal from './SimpleNotificationModal/SimpleNotificationModal';
import IModalData from '../../../Domain/Models/IModalData';
import NonDismissableNotificationModal from './NonDismissableNotificationModal/NonDismissableNotificationModal';

/**
 * The modal types.
 */
export enum BaseModalType
{
  /**
   * None.
   */
  None = 'None',

  /**
   * Generic Confirmation Modal.
   */
  GenericConfirmation = 'GenericConfirmation',

  /**
   * Simple Notification Modal.
   */
  SimpleNotification = 'SimpleNotification',

  /**
   * Non-dismissable notification.
   */
  NonDismissableNotification = 'NonDismissableNotification',
}

const InitializeModal = (): IModalData => ({
  modalType: '',
  data: undefined,
});

/**
 * Modal Controller.
 */
export class ModalController
{
  /**
   * The currently active modal.
   */
  public activeModal: IModalData = InitializeModal();

  /**
   * The generic confirmation modal error.
   */
  public genericConfirmationModalError = '';

  /**
   * History of previously opened modals.
   */
  private history: IModalData[] = [];

  /**
   * Show a modal.
   * @param modalType The modal type to show.
   */
  public show(modalData: IModalData): void
  {
    this.activeModal = modalData;
    this.history.push(modalData);
  }

  /**
   * Show the previously opened modal.
   */
  public showPrevious(): void
  {
    // Hide the current modal, and remove the current modal from the history
    this.hide();

    // Get the last opened modal.
    const lastOpenedModal = this.history[this.history.length - 1];

    // If the last modal exists, then show it.
    if (lastOpenedModal !== undefined)
    {
      this.show(lastOpenedModal);
    }
  }

  /**
   * Hide all modal.
   * @param modalType The modal type to hide.
   */
  public hide(): void
  {
    // Remove the last entry from the history.
    this.history.pop();

    this.genericConfirmationModalError = '';

    // Keep the existing data when hidden so it doesn't show the defaults
    // and is more economical if the same modal with the same data is requested again.
    this.activeModal = { modalType: BaseModalType.None, data: this.activeModal.data };
  }

  /**
   * Registers all the modal components.
   * @returns Modal components.
   */
  public registerModalComponents(modals: JSX.Element[]): JSX.Element
  {
    return (
      <>
        <GenericConfirmationModal key="GenericConfirmationModal" />
        <SimpleNotificationModal key="SimpleNotificationModal" />
        <NonDismissableNotificationModal key="NonDismissableNotification" />
        {modals}
      </>
    );
  }
}

decorate(ModalController, {
  activeModal: observable,
  genericConfirmationModalError: observable,
});

export const modalController = new ModalController();
