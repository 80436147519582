import React, { useEffect, useState } from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Checkbox from 'expo-checkbox';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { channelRepository } from '../../../Domain/Repositories/ChannelRepository';

interface ScreenListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  channelId: string;

  /**
   * The initial checkbox state
   */
  initialState?: boolean;

  /**
   * Callback when the checkbox was pressed.
   * @param id The selected id.
   */
  onCheckBoxSelected?: (state: boolean) => void;

  /**
   * Item index.
   */
  index?: number;

  /**
   * Disable the item.
   */
  disabled?: boolean;
}

/**
 * Renders the Channel Management List Item.
 */
export default function ChannelManagementListItem({
  itemHeight = RFValue(50),
  channelId,
  initialState = false,
  onCheckBoxSelected,
  index = 0,
  disabled = false,
}: ScreenListItemProps): JSX.Element
{
  const [isChecked, setChecked] = useState(false);

  useEffect(() =>
  {
    setChecked(initialState);
  }, [initialState]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      flexDirection: 'row',
      backgroundColor: index % 2 !== 0 ? Colors.BLACK(0.2) : undefined,
    },
    labelContainer: {
      flex: 0.9,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: '3%',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    checkboxContainer: {
      flex: 0.1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkbox: {
      height: '40%',
      width: undefined,
      aspectRatio: 1,
      overflow: 'hidden',
    },
  });

  const getChannelName = (): string =>
  {
    const channel = channelRepository.getById(channelId);
    return channel === undefined ? '' : channel.name;
  };

  const onCheckBoxPressed = (state: boolean): void =>
  {
    setChecked(state);

    if (onCheckBoxSelected !== undefined)
    {
      onCheckBoxSelected(state);
    }
  };

  return useObserver(() => (
    <View style={[styles.container, {
      opacity: disabled ? 0.5 : 1,
    }]}
    >
      <View style={styles.labelContainer}>
        <Text style={styles.label}>
          {getChannelName()}
        </Text>
      </View>
      <View style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkbox}
          value={isChecked}
          onValueChange={onCheckBoxPressed}
          color={isChecked ? Colors.APP.teal : Colors.APP.teal}
          disabled={disabled}
        />
      </View>
    </View>
  ));
}
