import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-native';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Fonts from '../../Domain/Types/Fonts';
import ChannelManagementListItem from './components/ChannelManagementListItem';
import { screenRepository } from '../../Domain/Repositories/ScreenRepository';
import { Colors } from '../../Config/Colors';
import { channelRepository } from '../../Domain/Repositories/ChannelRepository';
import useNavigation from '../../Domain/Hooks/useNavigation';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import { modalController, BaseModalType } from '../Shared/Modals/ModalController';
import UpdateChannelRequest from '../../Domain/Models/Requests/UpdateChannelRequest';
import ScreenService from '../../Domain/Services/ScreenService';

interface ChannelManagementScreenProps
{
  screenId: string;
}

/**
 * Renders the Channel Management screen.
 */
export default function ChannelManagementScreen(props: RouteComponentProps<{},
  never, ChannelManagementScreenProps>): JSX.Element
{
  const { navigateToScreen } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState<string>('');
  const [activeChannelId, setActiveChannelId] = useState('');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

  useEffect(() =>
  {
    setActiveChannelId('');
    setSelectedChannels([]);
    setScreen('');

    const { location } = props;
    if (location.state === undefined)
    {
      return;
    }

    const { screenId } = location.state;
    setScreen(screenId);

    const repoScreen = screenRepository.getById(screenId);
    if (repoScreen === undefined)
    {
      return;
    }

    const allChannels = repoScreen.channelIds.slice();
    const activeChannel = repoScreen.channelId;

    // Combine with the active channel.
    // Ensuring that the active channel is always the first element.
    if (activeChannel !== null)
    {
      setActiveChannelId(activeChannel);
      const index = allChannels.indexOf(activeChannel);

      // Remove existing occurrence of activeChannel.
      if (index !== -1)
      {
        allChannels.splice(index, 1);
      }

      // Set the active channel as the first element.
      allChannels.unshift(activeChannel);
    }

    setSelectedChannels(allChannels);
  }, [loading]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topBarContainer: {
      flex: 0.1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      backgroundColor: Colors.BLACK(0.4),
      flexDirection: 'row',
    },
    titleContainer: {
      flex: 1,
      width: '100%',
      backgroundColor: Colors.BLACK(0.4),
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    activityIndicator: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleLabelContainer: {
      flex: 0.76,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleParentContainer: {
      height: RFValue(13),
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    titleContentContainer: {
      height: '100%',
      width: '96%',
      justifyContent: 'center',
      flexDirection: 'row-reverse',
    },
    countTitleContainer: {
      height: '100%',
      flex: 3,
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.red,
    },
    countLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(6),
    },
    screenLabelContainer: {
      height: '100%',
      flex: 11,
      justifyContent: 'center',
    },
    screenLabel: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      width: '90%',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    optionsContainer: {
      flex: 0.24,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    saveButton: {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      height: RFValue(12),
      width: '100%',
      justifyContent: 'center',
      borderLeftWidth: RFValue(1.5),
      borderLeftColor: Colors.APP.teal,
    },
    label: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      width: '95%',
      paddingLeft: RFValue(5),
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    listContainer: {
      flex: 0.9,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    listView: {
      width: '100%',
      height: '95%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    flatList: {
      width: '100%',
      height: '100%',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    redirectButton: {
      height: RFValue(25),
      width: RFValue(90),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP.teal,
      marginTop: '2%',
    },
    redirectButtonLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
  });

  const onCheckBoxSelected = (state: boolean, channelId: string): void =>
  {
    if (state)
    {
      setSelectedChannels((previousValue: string[]) =>
      {
        if (previousValue.includes(channelId))
        {
          return previousValue;
        }

        const updated = previousValue.concat(channelId);
        return updated;
      });
      return;
    }

    setSelectedChannels((previousValue: string[]) =>
    {
      if (!previousValue.includes(channelId))
      {
        return previousValue;
      }

      const updated = previousValue.filter((x) => x !== channelId);
      return updated;
    });
  };

  const getContent = (): JSX.Element =>
  {
    const channels = channelRepository
      .getAll()
      .sort((a, b) =>
      {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB)
        {
          return 1;
        }

        if (nameA < nameB)
        {
          return -1;
        }

        return 0;
      })
      .map((x) => x.id);

    if (loading)
    {
      return (
        <ActivityIndicator color={Colors.APP.teal} size="large" />
      );
    }

    if (screen.trim() === '')
    {
      return (
        <>
          <Text style={styles.noDataAvailableLabel}>
            No screen selected
          </Text>

          <TouchableOpacity
            style={styles.redirectButton}
            onPress={(): void => navigateToScreen(ScreenTypes.VenueAreas)}
          >
            <Text style={styles.redirectButtonLabel}>
              Select
            </Text>
          </TouchableOpacity>
        </>
      );
    }

    if (channels.length !== 0)
    {
      return (
        <FlatList<string>
          style={styles.flatList}
          data={channels}
          renderItem={({ item, index }): JSX.Element => (
            <ChannelManagementListItem
              channelId={item}
              initialState={selectedChannels.includes(item)}
              onCheckBoxSelected={(state: boolean): void => onCheckBoxSelected(state, item)}
              index={index}
              disabled={item === activeChannelId}
            />
          )}
          keyExtractor={(item): string => JSON.stringify(item)}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      );
    }

    return (
      <Text style={styles.noDataAvailableLabel}>
        No screens available
      </Text>
    );
  };

  const getTitleContent = (): JSX.Element =>
  {
    if (loading)
    {
      return (
        <View style={styles.activityIndicator}>
          <ActivityIndicator color={Colors.APP.teal} size="large" />
        </View>
      );
    }

    if (screen.trim() === '')
    {
      return (<></>);
    }

    const repoScreen = screenRepository.getById(screen);
    const title = repoScreen === undefined ? '' : repoScreen.name;

    return (
      <View style={styles.titleParentContainer}>
        <View style={styles.titleContentContainer}>
          <View style={styles.countTitleContainer}>
            <Text style={styles.countLabel}>
              {`${selectedChannels.length} / ${channelRepository.all.length}`}
            </Text>
          </View>
          <View style={styles.screenLabelContainer}>
            <Text style={styles.screenLabel}>
              {title}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const onSavePressed = async (): Promise<void> =>
  {
    if (screen.trim() === '')
    {
      showNotification('No screen selected! Select one and try again.');
      return;
    }

    const repoScreen = screenRepository.getById(screen);
    if (repoScreen === undefined)
    {
      showNotification('Unable to locate the screen! Select one and try again.');
      return;
    }

    const allChannels = repoScreen
      .channelIds
      .filter((x) => x !== repoScreen.channelId);

    if (repoScreen.channelId !== null)
    {
      allChannels.push(repoScreen.channelId);
    }

    if (allChannels.length === selectedChannels.length)
    {
      showNotification('Nothing to save!', 'Info');
      return;
    }

    if (repoScreen.channelId !== null && !selectedChannels.includes(repoScreen.channelId))
    {
      showNotification('You cannot remove a channel that is currently active!', 'Error');
      return;
    }

    setLoading(true);
    const request: UpdateChannelRequest = {
      screenId: screen,
      channelIds: selectedChannels,
    };

    const response = await ScreenService.updateChannels(request);
    setLoading(false);

    if (response.success)
    {
      showNotification('Successfully updated channels!');
      return;
    }

    showNotification(response.reason, 'Error');
  };

  const onBackButtonPressed = (): void =>
  {
    navigateToScreen(ScreenTypes.ChannelControl, {
      screenId: screen,
    });
  };

  const showNotification = (reason: string, title = 'Info'): void =>
  {
    const notification: INotificationModalData = {
      title,
      message: reason,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.ChannelManagement}
      onSyncProgressChanged={setLoading}
      leftButtonAction={onBackButtonPressed}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.topBarContainer}>
            <View style={styles.titleLabelContainer}>
              {getTitleContent()}
            </View>

            <View style={styles.optionsContainer}>
              <TouchableOpacity
                style={[styles.saveButton, {
                  opacity: screen.trim() === '' ? 0.5 : 1,
                }]}
                disabled={screen.trim() === ''}
                onPress={onSavePressed}
              >
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>
                    Update Selection
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.listContainer}>
            <View style={styles.listView}>
              {getContent()}
            </View>
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
