/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import { Dimensions } from 'react-native';

/**
 * The dynamic screen repository.
 */
export class ResponsiveScreenRepository
{
  /**
   * Screen loaded flag.
   */
  public loaded = false;

  /**
   * The refresh key. This changes when the screen width or height changes.
   */
  public key = 0;

  /**
   * The new window width.
   */
  public newWidth = Dimensions.get('window').width;

  /**
   * The new window height.
   */
  public newHeight = Dimensions.get('window').height;

  /**
   * Subscribes to the changes in the window dimensions.
   */
  public subscribe(): void
  {
    // Callback when the window dimensions changes.
    Dimensions.addEventListener('change', async (): Promise<void> =>
    {
      this.newHeight = Dimensions.get('window').height;
      this.newWidth = Dimensions.get('window').width;
      this.key = this.newHeight * this.newWidth;
    });
  }
}

decorate(ResponsiveScreenRepository, {
  loaded: observable,
  key: observable,
  newHeight: observable,
  newWidth: observable,
});

export const responsiveScreenRepository = new ResponsiveScreenRepository();
