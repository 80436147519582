/**
 * The Modal Types.
 */
enum ModalType
{
  CreateAreaModal = 'CreateAreaModal',
  AssignScreenAreaModal = 'AssignScreenAreaModal',
}

export default ModalType;
