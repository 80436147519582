import Logger from '../Logger/Logger';
import { areaRepository } from '../Repositories/AreaRepository';
import { channelRepository } from '../Repositories/ChannelRepository';
import { roleRepository } from '../Repositories/RoleRepository';
import { screenRepository } from '../Repositories/ScreenRepository';
import { sessionTokenRepository } from '../Repositories/SessionTokenRepository';
import { userRepository } from '../Repositories/UserRepository';
import AreaService from './AreaService';
import ChannelService from './ChannelService';
import ScreenService from './ScreenService';
import SystemService from './SystemService';

const logger = Logger.Create('SessionService');

/**
 * Handles the Session operations.
 */
export default class SessionService
{
  /**
   * Handles all logout functionalities.
   */
  public static logout(): void
  {
    logger.info('Clearing repositories...');

    roleRepository.clearAll();
    sessionTokenRepository.clear();
    userRepository.clearAll();
    areaRepository.clearAll();
    screenRepository.clearAll();
    channelRepository.clearAll();

    logger.info('Resetted all the repositories.');
  }

  /**
   * Grab all the data.
   */
  public static async initialise(): Promise<void>
  {
    await SystemService.sync();
    await AreaService.get();
    await ScreenService.get();
    await ChannelService.get();
  }
}
