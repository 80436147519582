import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-native';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import { Colors } from '../../Config/Colors';
import Fonts from '../../Domain/Types/Fonts';
import AreaResponse from '../../Domain/Models/Responses/AreaResponse';
import { areaRepository } from '../../Domain/Repositories/AreaRepository';
import AreaListItem from './components/AreaListItem';
import CreateAreaModal from '../Shared/Modals/CreateAreaModal/CreateAreaModal';
import ModalType from '../../Domain/Types/ModalType';
import AreaScreenListItem from './components/AreaScreenListItem';
import PartySelection from './components/PartySelection';
import useNavigation from '../../Domain/Hooks/useNavigation';
import Icons from '../../Assets/Icons';
import AssignScreenModalData from '../../Domain/Models/AssignScreenModalData';
import AssignScreenModal from '../Shared/Modals/AssignScreenAreaModal/AssignScreenAreaModal';
import CreateAreaModalData from '../../Domain/Models/CreateAreaModalData';
import Utils from '../../Domain/Utils/Utils';
import IConfirmationDialogModel from '../../Domain/Models/IConfirmationDialogModel';
import RefreshScreenRequest from '../../Domain/Models/Requests/RefreshScreenRequest';
import ScreenService from '../../Domain/Services/ScreenService';
import INotificationModalData from '../../Domain/Models/INotificationModalData';

const EnablePartySelection = false;

interface VenueAreasScreenProp
{
  areaId: string;
}

/**
 * Renders the Venue Area screen.
 */
export default function VenueAreasScreen(props: RouteComponentProps<{},
  never, VenueAreasScreenProp>): JSX.Element
{
  const { navigateToScreen } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState('');
  const flatListRef = useRef<FlatList>(null);

  useEffect(() =>
  {
    if (loading)
    {
      return;
    }

    const { location } = props;
    if (location.state === undefined)
    {
      setSelectedArea('30a3d4b9-8c6f-4a24-897c-557bf005049d');
      return;
    }

    const { areaId } = location.state;

    // Double check if it still exists.
    const area = areaRepository.getById(areaId);

    if (area === undefined)
    {
      setSelectedArea('30a3d4b9-8c6f-4a24-897c-557bf005049d');
      return;
    }

    setSelectedArea(areaId);
  }, [loading]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    areaContainer: {
      flex: 0.1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.BLACK(0.4),
    },
    areaListContainer: {
      width: '93%',
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    areaFlatList: {
      width: '100%',
      height: '100%',
    },
    areaContentFlatList: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    topBarContainer: {
      flex: 0.1,
      width: '100%',
      backgroundColor: Colors.BLACK(0.4),
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    titleLabelContainer: {
      flex: 0.5,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleLabel: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      paddingLeft: '7%',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    activityIndicator: {
      position: 'absolute',
      left: '7%',
    },
    optionsContainer: {
      flex: 0.5,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    addAreaButtonContainer: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    addAreaButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      height: RFValue(12),
      width: '90%',
      justifyContent: 'center',
      borderLeftWidth: RFValue(1.5),
      borderLeftColor: Colors.APP.teal,
    },
    label: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      width: '100%',
      paddingLeft: RFValue(5),
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    assignScreensButtonContainer: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    assignScreenButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    areaScreensContainer: {
      flex: 0.8,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    partyView: {
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.BLACK(0.2),
    },
    partyContentContainer: {
      width: '95%',
      height: '90%',
    },
    listView: {
      width: '100%',
      flex: 3,
      justifyContent: 'center',
      alignItems: 'center',
    },
    screensFlatList: {
      width: '100%',
      height: '100%',
    },
  });

  const onAreaSelected = (id: string): void =>
  {
    setSelectedArea(id);
  };

  const getListContent = (): JSX.Element =>
  {
    const areas = areaRepository.getAll();

    if (loading)
    {
      return (
        <ActivityIndicator
          color={Colors.APP.teal}
          size="large"
        />
      );
    }

    if (areas.length !== 0)
    {
      return (
        <FlatList<AreaResponse>
          ref={flatListRef}
          style={styles.areaFlatList}
          contentContainerStyle={styles.areaContentFlatList}
          data={areas}
          renderItem={({ item }): JSX.Element => (
            <AreaListItem
              areaId={item.id}
              onAreaSelected={(): void => onAreaSelected(item.id)}
              isSelected={item.id === selectedArea}
            />
          )}
          keyExtractor={(item): string => JSON.stringify(item)}
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      );
    }

    return (
      <Text style={styles.noDataAvailableLabel}>
        No areas available.
      </Text>
    );
  };

  const renderScreenView = (): JSX.Element =>
  {
    const area = areaRepository.getById(selectedArea);
    if (loading)
    {
      return (
        <ActivityIndicator color={Colors.APP.teal} size="large" />
      );
    }

    if (selectedArea.trim() === '' || area === undefined)
    {
      return (
        <Text style={styles.noDataAvailableLabel}>
          No area selected.
        </Text>
      );
    }

    if (area.screens.length === 0)
    {
      return (
        <Text style={styles.noDataAvailableLabel}>
          No screens assigned in this area.
        </Text>
      );
    }

    const screenIdsInArea = area.screens.map((x) => x.id);

    return (
      <View style={styles.listContainer}>
        {EnablePartySelection && (
          <View style={styles.partyView}>
            <View style={styles.partyContentContainer}>
              <PartySelection />
            </View>
          </View>
        )}

        <View style={styles.listView}>
          <FlatList<string>
            style={styles.screensFlatList}
            data={screenIdsInArea}
            renderItem={({ item, index }): JSX.Element => (
              <AreaScreenListItem
                screenId={item}
                onControlAreaScreenPressed={(): void => onControlAreaScreenPressed(item)}
                onRefreshScreenPressed={(): void => onRefreshScreenPressed(item)}
                onEditAreaPressed={(): void => onEditAreaPressed(item)}
                index={index}
              />
            )}
            keyExtractor={(item): string => JSON.stringify(item)}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>

    );
  };

  const onAddAreaSelected = (): void =>
  {
    const data: CreateAreaModalData = {
      onSuccess: async (): Promise<void> =>
      {
        // Arbitrary time to wait until the
        // data is populate before scrolling.
        await Utils.delay(200);
        if (flatListRef.current !== null)
        {
          flatListRef.current.scrollToEnd();
        }
      },
    };

    modalController.show({
      modalType: ModalType.CreateAreaModal,
      data,
    });
  };

  const onAssignScreensSelected = (): void =>
  {
    navigateToScreen(ScreenTypes.ScreenList);
  };

  const onControlAreaScreenPressed = (screenId: string): void =>
  {
    navigateToScreen(ScreenTypes.ChannelControl, {
      screenId,
    });
  };

  const onRefreshScreenPressed = (screenId: string): void =>
  {
    const data: IConfirmationDialogModel = {
      message: 'Are you sure you want to\nrefresh the screen?',
      title: 'Info',
      positiveAction: async () =>
      {
        await refreshScreen(screenId);
      },
      negativeAction: (): void => modalController.hide(),
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data,
    });
  };

  const onEditAreaPressed = (screenId: string): void =>
  {
    const modalData: AssignScreenModalData = {
      screenId,
    };

    modalController.show({
      modalType: ModalType.AssignScreenAreaModal,
      data: modalData,
    });
  };
  
  const refreshScreen = async (screenId: string): Promise<void> =>
  {
    setLoading(true);
    const request: RefreshScreenRequest = {
      screenId: screenId,
    };

    const response = await ScreenService.refreshScreen(request);
    setLoading(false);

    if (response.success)
    {
      showNotification('Screen successfully refreshed.');
      return;
    }

    showNotification(response.reason, true);
  };

  const showNotification = (message: string, error = false): void =>
    {
      const data: INotificationModalData = {
        title: error ? 'Error' : 'Info',
        message: message,
      };
  
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data,
      });
    };

  const getTitleContent = (): JSX.Element =>
  {
    if (loading)
    {
      return (
        <ActivityIndicator
          color={Colors.APP.teal}
          size="large"
          style={styles.activityIndicator}
        />
      );
    }

    if (selectedArea.trim() === '')
    {
      return (<></>);
    }

    const area = areaRepository.getById(selectedArea);
    const title = area === undefined ? '' : area.name;

    return (
      <Text style={styles.titleLabel}>
        {title}
      </Text>
    );
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.VenueAreas}
      onSyncProgressChanged={setLoading}
      registerModals={[
        <CreateAreaModal key="CreateAreaModal" />,
        <AssignScreenModal key="AssignScreenModal" />,
      ]}
      leftButtonIcon={Icons.LogOut}
      leftButtonAction={(): void => navigateToScreen(ScreenTypes.Login)}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.topBarContainer}>
            <View style={styles.titleLabelContainer}>
              {getTitleContent()}
            </View>

            <View style={styles.optionsContainer}>
              <View style={styles.addAreaButtonContainer}>
                <TouchableOpacity
                  style={[styles.addAreaButton, {
                    // Can't put in the stylesheet.
                    // Screen goes blank without errors.
                    opacity: loading ? 0.3 : 1,
                  }]}
                  disabled={loading}
                  onPress={onAddAreaSelected}
                >
                  <View style={styles.labelContainer}>
                    <Text style={styles.label}>
                      Create New Area
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.assignScreensButtonContainer}>
                <TouchableOpacity
                  style={[styles.assignScreenButton, {
                    // Can't put in the stylesheet.
                    // Screen goes blank without errors.
                    opacity: loading ? 0.3 : 1,
                  }]}
                  disabled={loading}
                  onPress={onAssignScreensSelected}
                >
                  <View style={styles.labelContainer}>
                    <Text style={styles.label}>
                      Manage Screens
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View style={styles.areaContainer}>
            <View style={styles.areaListContainer}>
              {getListContent()}
            </View>
          </View>

          <View style={styles.areaScreensContainer}>
            {renderScreenView()}
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
