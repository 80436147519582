/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import Users from '../Models/Users';
import LocalStorageService from '../Services/LocalStorageService';
import { Constants } from '../../Config/Constants';

/**
 * User Repository.
 */
export class UserRepository
{
  /**
   * The current user.
   */
  public currentUser: Users = Constants.DEFAULT_USER;

  /**
   * All users.
   */
  public allUsers: Users[] = [];

  protected currentUserStorage: LocalStorageService<Users> = new LocalStorageService<Users>('currentUser');

  /**
   * Updates the current user.
   * @param user The user.
   */
  public async updateCurrentUser(user: Users): Promise<void>
  {
    this.currentUser = user;
    await this.currentUserStorage.save(user);
  }

  /**
   * Retrieves the user from storage.
   */
  public async retrieveFromStorage(): Promise<void>
  {
    const value = await this.currentUserStorage.load();
    this.currentUser = value ?? Constants.DEFAULT_USER;
  }

  /**
   * Updates the current user.
   * @param users The users.
   */
  public updateAllUsers(users: Users[]): void
  {
    const sortedUsers = users
      .filter((x) => x.id !== this.currentUser.id) // Remove the current user.
      .sort((a: Users, b: Users) => a.userName.toLowerCase() // Sort alphabetically by Username.
        .localeCompare(b.userName.toLowerCase()));

    sortedUsers.unshift(this.currentUser); // Add current user to the top of the list.
    this.allUsers = sortedUsers;
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.currentUser = Constants.DEFAULT_USER;
    this.allUsers.length = 0;
    this.currentUserStorage.clear();
  }
}

decorate(UserRepository, {
  currentUser: observable,
  allUsers: observable,
});

export const userRepository = new UserRepository();
