/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import ChannelResponse from '../Models/Responses/ChannelResponse';

/**
 * Channel Repository.
 */
export class ChannelRepository
{
  /**
   * The available Channel.
   */
  public all: ChannelResponse[] = [];

  /**
   * Get the available Channels.
   * @returns Channel Response.
   */
  public getAll(): ChannelResponse[]
  {
    return this
      .all
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  /**
   * Replaces all the channels.
   * @param all The channels.
   */
  public replace(all: ChannelResponse[]): void
  {
    this.all = all;
  }

  /**
   * Adds or updates the repository.
   * @param channel The Channel to add or update.
   */
  public addOrUpdate(channel: ChannelResponse): void
  {
    const index = this.all.findIndex((x) => x.id === channel.id);

    if (index === -1)
    {
      this.all.push(channel);
      return;
    }

    this.all[index] = channel;
  }

  /**
   * Remove.
   * @param id The id.
   */
  public async remove(id: string): Promise<void>
  {
    const clone = [...this.all];
    const filtered = clone.filter((x) => x.id !== id);
    this.all = filtered;
  }

  /**
   * Get the Channel by id.
   * @param id The id.
   * @returns The Channel response.
   */
  public getById(id: string): ChannelResponse | undefined
  {
    return this.all.find((x) => x.id === id);
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.all.length = 0;
  }
}

decorate(ChannelRepository, {
  all: observable,
});

export const channelRepository = new ChannelRepository();
