import React, { useEffect, useRef } from 'react';
import {
  Animated,
  ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import useIsMounted from '../../Domain/Hooks/useIsMounted';
import useNavigation from '../../Domain/Hooks/useNavigation';
import { sessionTokenRepository } from '../../Domain/Repositories/SessionTokenRepository';
import LoginRedirect from '../LoginRedirect/LoginRedirect';
import { responsiveScreenRepository } from '../../Domain/Repositories/ResponsiveScreenRepository';
import Header from '../Header/Header';
import { Colors } from '../../Config/Colors';
import { BaseModalType, modalController } from './Modals/ModalController';
import SystemService from '../../Domain/Services/SystemService';
import AreaService from '../../Domain/Services/AreaService';
import ScreenService from '../../Domain/Services/ScreenService';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import Utils from '../../Domain/Utils/Utils';
import ChannelService from '../../Domain/Services/ChannelService';

/**
 * The Parent View Controller Props.
 */
interface ParentViewControllerProps
{
  /**
  * The screen type.
  */
  screenType: ScreenTypes;

  /**
  * Children components.
  */
  children?: JSX.Element[] | JSX.Element;

  /**
  * Ignores the login redirect.
  */
  ignoreLoginRedirect?: boolean;

  /**
  * Automatically sync on screen enter.
  *
  * WARNING: Only syncOnScreenEnter or refreshOnScreenEntre
  * can be true.
  *
  * Defaults to false.
  */
  syncOnScreenEnter?: boolean;

  /**
  * Automatically refresh on screen enter.
  *
  * WARNING: Only syncOnScreenEnter or refreshOnScreenEntre
  * can be true.
  *
  * Defaults to true.
  */
  refreshOnScreenEnter?: boolean;

  /**
   * Callback when the sync/refresh progress changed.
   * @param inProgress Sync/refresh progress state.
   */
  onSyncProgressChanged?: (inProgress: boolean) => void;

  /**
   * The modals to register.
   */
  registerModals?: JSX.Element[];

  /**
 * The left button icon.
 */
  leftButtonIcon?: ImageSourcePropType;

  /**
   * Callback when the left button was pressed.
   * Defaults to goBack.
   */
  leftButtonAction?: () => void;
}

/**
 * Component that handles the generic/shared activities.
 * @returns JSX.Element.
 */
export default function ParentViewController({
  children,
  screenType,
  ignoreLoginRedirect = false,
  syncOnScreenEnter = false,
  refreshOnScreenEnter = true,
  onSyncProgressChanged,
  registerModals = [],
  leftButtonIcon,
  leftButtonAction,
}: ParentViewControllerProps): JSX.Element
{
  const opacityAnimatedValue = useRef(new Animated.Value(0)).current;
  const { navigateToScreen, goBack } = useNavigation();
  const isMounted = useIsMounted();

  useEffect(() =>
  {
    (async (): Promise<void> =>
    {
      const token = await sessionTokenRepository.getJwtToken();

      if (token === '' && screenType !== ScreenTypes.Login)
      {
        navigateToScreen(ScreenTypes.Login);
      }
    })();
  }, []);

  // useEffect for handling container fade animation
  useEffect(() =>
  {
    if (isMounted())
    {
      Animated.timing(
        opacityAnimatedValue,
        {
          toValue: 1,
          duration: 0,
          useNativeDriver: false,
        },
      ).start();
    }
  }, [opacityAnimatedValue, isMounted]);

  useEffect(() =>
  {
    if (!syncOnScreenEnter)
    {
      return;
    }

    (async (): Promise<void> =>
    {
      await syncAndRefresh();
    })();
  }, []);

  useEffect(() =>
  {
    if (!refreshOnScreenEnter)
    {
      return;
    }

    (async (): Promise<void> =>
    {
      await refreshOnly();
    })();
  }, []);

  const updateSyncProgress = (inProgress: boolean): void =>
  {
    if (onSyncProgressChanged !== undefined)
    {
      onSyncProgressChanged(inProgress);
    }
  };

  const syncAndRefresh = async (): Promise<void> =>
  {
    updateSyncProgress(true);

    const notification: INotificationModalData = {
      message: 'Syncing in progress...',
    };

    modalController.show({
      modalType: BaseModalType.NonDismissableNotification,
      data: notification,
    });

    const syncResponse = await SystemService.sync();
    if (!syncResponse.success)
    {
      await showFailedNotification(syncResponse.reason);
      return;
    }

    const success = await getData();

    if (success)
    {
      // Hide the non-dismissable notification.
      modalController.hide();
    }

    updateSyncProgress(false);
  };

  const refreshOnly = async (): Promise<void> =>
  {
    updateSyncProgress(true);

    const notification: INotificationModalData = {
      message: 'Refreshing data...',
    };

    modalController.show({
      modalType: BaseModalType.NonDismissableNotification,
      data: notification,
    });

    const success = await getData();

    if (success)
    {
      // Hide the non-dismissable notification.
      modalController.hide();
    }

    updateSyncProgress(false);
  };

  const getData = async (): Promise<boolean> =>
  {
    const areaResponse = await AreaService.get();
    if (!areaResponse.success)
    {
      await showFailedNotification(areaResponse.reason);
      return false;
    }

    const screenResponse = await ScreenService.get();
    if (!screenResponse.success)
    {
      await showFailedNotification(areaResponse.reason);
      return false;
    }

    const channelResponse = await ChannelService.get();
    if (!channelResponse.success)
    {
      await showFailedNotification(areaResponse.reason);
      return false;
    }

    return true;
  };

  const showFailedNotification = async (reason: string): Promise<void> =>
  {
    modalController.hide();
    updateSyncProgress(false);

    // Wait until the modal has closed first.
    await Utils.delay(200);

    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const onLeftButtonPressed = (): void =>
  {
    if (leftButtonAction === undefined)
    {
      goBack();
      return;
    }

    leftButtonAction();
  };

  return useObserver(() => (
    <Animated.View
      style={[styles.container, {
        opacity: opacityAnimatedValue,
        width: responsiveScreenRepository.newWidth,
        height: responsiveScreenRepository.newHeight,
      }]}
    >
      {!ignoreLoginRedirect && <LoginRedirect />}

      <View style={styles.contentContainer}>
        <View style={styles.headerContainer}>
          <Header
            screenType={screenType}
            onSyncButtonSelected={syncAndRefresh}
            onRefreshButtonSelected={refreshOnly}
            leftButtonAction={onLeftButtonPressed}
            leftButtonIcon={leftButtonIcon}
          />
        </View>

        <View style={styles.childrenContainer}>
          {children}
        </View>
      </View>

      {modalController.registerModalComponents(registerModals)}
    </Animated.View>
  ));
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    backgroundColor: Colors.APP.darkBlue,
  },
  contentContainer: {
    flex: 1,
  },
  headerContainer: {
    flex: 0.1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
  childrenContainer: {
    flex: 0.9,
    width: '100%',
  },
});
