import React, { useState } from 'react';
import {
  StyleSheet, Switch, Text, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';

/**
 * Renders the Party Selection.
 */
export default function PartySelection(): JSX.Element
{
  const [isEnabled, setIsEnabled] = useState(true);
  const toggle = (): void => setIsEnabled((previousState) => !previousState);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
    },
    displayForOnePartyContainer: {
      flex: 0.5,
      width: '100%',
      flexDirection: 'row',
    },
    labelContainer: {
      flex: 0.3,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '90%',
      textAlign: 'left',
    },
    switchContainer: {
      flex: 0.7,
      width: '100%',
      justifyContent: 'center',
    },
    switch: {
      transform: [{
        scale: RFValue(0.85),
      }],
      marginLeft: RFValue(15),
    },
    partySelectionContainer: {
      flex: 0.5,
      width: '100%',
      flexDirection: 'row',
    },
    partyInputContainer: {
      flex: 0.7,
      width: '100%',
      backgroundColor: Colors.APP.teal,
      justifyContent: 'center',
      alignItems: 'center',
    },
    partyInputLabel: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '95%',
      textAlign: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.displayForOnePartyContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            Display info for only 1 party
          </Text>
        </View>

        <View style={styles.switchContainer}>
          <Switch
            trackColor={{ false: Colors.APP.red, true: Colors.APP.green }}
            thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
            onValueChange={toggle}
            value={isEnabled}
            style={styles.switch}
            activeThumbColor="white"
          />
        </View>
      </View>

      <View style={styles.partySelectionContainer}>
        {isEnabled && (
          <>
            <View style={styles.labelContainer}>
              <Text style={styles.label}>
                Party to display
              </Text>
            </View>
            <View style={styles.partyInputContainer}>
              <Text style={styles.partyInputLabel}>
                Select A Party (WIP)
              </Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
}
