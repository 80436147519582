import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import RefreshScreenRequest from '../Models/Requests/RefreshScreenRequest';
import SetChannelRequest from '../Models/Requests/SetChannelRequest';
import UpdateChannelRequest from '../Models/Requests/UpdateChannelRequest';
import UpdateScreenAreaRequest from '../Models/Requests/UpdateScreenAreaRequest';
import ScreenResponse from '../Models/Responses/ScreenResponse';
import { screenRepository } from '../Repositories/ScreenRepository';
import ApiService from './ApiService';

const logger = Logger.Create('ScreenService');

/**
 * Handles screen operations.
 */
export default class ScreenService
{
  /**
   * Get the available screens.
   * @returns Promise.
   */
  public static async get(): Promise<PromiseResponse<ScreenResponse[]>>
  {
    const url = '/api/Screen';

    return ApiService.get<ScreenResponse[]>(url)
      .then((response: ScreenResponse[]) =>
      {
        screenRepository.replace(response);
        return PromiseSuccess<ScreenResponse[]>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to get screens: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Assign the screen's area.
   * @returns Promise.
   */
  public static async assignArea(request: UpdateScreenAreaRequest):
    Promise<PromiseResponse<ScreenResponse>>
  {
    const url = '/api/Screen';

    return ApiService.put<ScreenResponse>(url, request)
      .then((response: ScreenResponse) =>
      {
        screenRepository.addOrUpdate(response);
        return PromiseSuccess<ScreenResponse>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to update screen area: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Update the screen channels
   * @returns Promise.
   */
  public static async updateChannels(request: UpdateChannelRequest):
    Promise<PromiseResponse<ScreenResponse>>
  {
    const url = '/api/Screen/UpdateAvailableChannels';

    return ApiService.put<ScreenResponse>(url, request)
      .then((response: ScreenResponse) =>
      {
        screenRepository.addOrUpdate(response);
        return PromiseSuccess<ScreenResponse>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to update channels: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Sets the screen's channel.
   * @returns Promise.
   */
  public static async setChannel(request: SetChannelRequest):
    Promise<PromiseResponse<ScreenResponse>>
  {
    const url = '/api/Screen/SetChannel';

    return ApiService.put<ScreenResponse>(url, request)
      .then((response: ScreenResponse) =>
      {
        screenRepository.addOrUpdate(response);
        return PromiseSuccess<ScreenResponse>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to set the channel: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Refresh a screen.
   * @returns Promise.
   */
  public static async refreshScreen(request: RefreshScreenRequest):
    Promise<PromiseResponse<ScreenResponse>>
  {
    const url = '/api/Screen/Refresh';

    return ApiService.put<ScreenResponse>(url, request)
      .then((response: ScreenResponse) =>
      {
        screenRepository.addOrUpdate(response);
        return PromiseSuccess<ScreenResponse>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to set the channel: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }
}
