/**
 * The available roles.
 */
enum Roles
{
  /**
   * Admin.
   */
  Admin = 'Admin',

  /**
   * Manager.
   */
  Manager = 'Manager',

  /**
   * Supervisor.
   */
  Supervisor = 'Supervisor',

  /**
   * Operator.
   */
  Operator = 'Operator',
}

export default Roles;
