import React from 'react';
import {
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import Fonts from '../../Domain/Types/Fonts';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Icons from '../../Assets/Icons';
import { Colors } from '../../Config/Colors';

interface HeaderProps
{
  /**
   * The screen type.
   */
  screenType?: ScreenTypes;

  /**
   * Callback when the sync button was pressed.
   */
  onSyncButtonSelected?: () => void;

  /**
   * Callback when the refresh was pressed.
   */
  onRefreshButtonSelected?: () => void;

  /**
   * The left button icon.
   */
  leftButtonIcon?: ImageSourcePropType;

  /**
   * Callback when the left button was pressed.
   */
  leftButtonAction?: () => void;
}

/**
 * Renders the header.
 */
export default function Header({
  screenType = ScreenTypes.Login,
  onSyncButtonSelected,
  onRefreshButtonSelected,
  leftButtonIcon = Icons.Back,
  leftButtonAction,
}: HeaderProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    leftButtonContainer: {
      flex: 0.1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftButtonIcon: {
      width: RFValue(11),
      height: undefined,
      aspectRatio: 1,
      position: 'absolute',
      left: '32%',
    },
    titleContainer: {
      flex: 0.65,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '100%',
      textAlign: 'left',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    optionsContainer: {
      flex: 0.25,
      height: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
    },
    syncButton: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    refreshButton: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      height: RFValue(12),
      width: '80%',
      justifyContent: 'center',
      borderLeftWidth: RFValue(1.5),
      borderLeftColor: Colors.APP.teal,
    },
    syncLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      width: '100%',
      paddingLeft: RFValue(5),
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
  });

  const getHeaderTitle = (): string =>
  {
    switch (screenType)
    {
      case ScreenTypes.ScreenList:
        return 'Unassigned Screens';
      case ScreenTypes.VenueAreas:
        return 'Venue Areas';
      case ScreenTypes.ChannelControl:
        return 'Channel Control';
      case ScreenTypes.ChannelManagement:
        return 'Channel Management';
      default:
        return '';
    }
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.leftButtonContainer}>
          <TouchableOpacity
            style={styles.leftButton}
            onPress={leftButtonAction}
          >
            <Image
              source={leftButtonIcon}
              style={styles.leftButtonIcon}
              contentFit="contain"
              responsivePolicy="initial"
            />
          </TouchableOpacity>
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {getHeaderTitle()}
          </Text>
        </View>

        <View style={styles.optionsContainer}>
          <TouchableOpacity
            style={styles.syncButton}
            onPress={onSyncButtonSelected}
          >
            <View style={styles.labelContainer}>
              <Text style={styles.syncLabel}>
                Sync
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.refreshButton}
            onPress={onRefreshButtonSelected}
          >
            <View style={styles.labelContainer}>
              <Text style={styles.syncLabel}>
                Refresh
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  ));
}
