/* eslint-disable import/prefer-default-export */

import Environment from '../Domain/Models/Environment';
import OidConfig from '../Domain/Models/OidConfig';

const Local: Environment = {
  apiBase: 'https://host.docker.internal:5181',
  jwtAuthorityBaseUrl: 'https://host.docker.internal:5161/connect/token',
  clientSecret: 'dev123',
};

const Manchester: Environment = {
  apiBase: window.location.origin,
  jwtAuthorityBaseUrl: 'https://up-sso.urbanplaygroundtech.co.uk/connect/token',
  clientSecret: 'JDm7xLEGwFWQX3vomtIfBBoTNuJRhoBQ',
};

const Staging: Environment = {
  apiBase: window.location.origin,
  jwtAuthorityBaseUrl: 'https://up-sso-staging.azurewebsites.net/connect/token',
  clientSecret: 'B1wLMYKRXDCYBIascfa5Supu9nxLjAO4',
};

const CanaryWharf: Environment = {
  apiBase: window.location.origin,
  jwtAuthorityBaseUrl: 'https://up-sso.urbanplaygroundtech.co.uk/connect/token',
  clientSecret: 'JDm7xLEGwFWQX3vomtIfBBoTNuJRhoBQ',
};

/**
 * Returns the environment configuration based on the config.
 * @returns The environment.
 */
export const getEnvironment = (): Environment =>
{
  switch (window.config.REACT_ENVIRONMENT)
  {
    case 'Manchester':
      return Manchester;
    case 'Staging':
      return Staging;
    case 'CanaryWharf':
      return CanaryWharf;
    case 'Local':
    default:
      return Local;
  }
};

/**
 * Gets the OID config.
 * @returns OID Config.
 */
export const getOidConfig = (): OidConfig =>
{
  const env = getEnvironment();

  return {
    authenticationUrl: env.jwtAuthorityBaseUrl,
    clientSecret: env.clientSecret,
    clientId: 'UrbanPlayground.DynamicRemoteApp',
    grantType: 'password',
    scope: 'TheCube.Server.Api UrbanPlayground.DynamicRemote.Api',
  };
};
