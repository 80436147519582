import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { screenRepository } from '../../../Domain/Repositories/ScreenRepository';

interface AreaScreenListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  screenId: string;

  /**
   * Callback when the control screen was pressed.
   */
  onControlAreaScreenPressed?: () => void;

  /**
   * Callback when the refresh screen was pressed.
   */
  onRefreshScreenPressed?: () => void;

  /**
   * Callback when the edit area was pressed.
   */
  onEditAreaPressed?: () => void;

  /**
   * Item index.
   */
  index?: number;
}

/**
 * Renders the Screen List Item.
 */
export default function AreaScreenListItem({
  itemHeight = RFValue(50),
  screenId,
  onControlAreaScreenPressed,
  onRefreshScreenPressed,
  onEditAreaPressed,
  index = 0,
}: AreaScreenListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      backgroundColor: index % 2 !== 0 ? Colors.BLACK(0.2) : undefined,
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '95%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    labelContainer: {
      flex: 0.63,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      fontSize: RFValue(13),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      marginLeft: '2%',
    },
    buttonContainer: {
      flex: 0.37,
      height: '100%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row-reverse',
    },
    setChannelButton: {
      width: '35%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    refreshScreenButton: {
      width: '35%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    editAreaButton: {
      width: '30%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
      marginRight: RFValue(4),
    },
    buttonLabel: {
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
    },
  });

  const getScreenName = (): string =>
  {
    const screen = screenRepository.getById(screenId);
    return screen === undefined ? '' : screen.name;
  };

  const refreshScreenPressed = (): void =>
  {
    if (onRefreshScreenPressed !== undefined)
    {
      onRefreshScreenPressed();
    }
  }

  const controlScreenPressed = (): void =>
  {
    if (onControlAreaScreenPressed !== undefined)
    {
      onControlAreaScreenPressed();
    }
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            {getScreenName()}
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.setChannelButton}
            onPress={controlScreenPressed}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Set Channel
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.refreshScreenButton}
            onPress={refreshScreenPressed}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Refresh Screen
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.editAreaButton}
            onPress={onEditAreaPressed}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Edit Area
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  ));
}
