/**
 * The log levels.
 */
enum LogLevel
{
  /**
   * Trace.
   */
  Trace = 'Trace',

  /**
   * Info.
   */
  Info = 'Info',

  /**
   * Debug.
   */
  Debug = 'Debug',

  /**
   * Warn.
   */
  Warn = 'Warn',

  /**
   * Error.
   */
  Error = 'Error',

  /**
   * Fatal.
   */
  Fatal = 'Fatal',
}

export default LogLevel;
