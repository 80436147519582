import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { areaRepository } from '../../../Domain/Repositories/AreaRepository';

interface AreaListItemProps
{
  /**
   * The item height.
   */
  itemWidth?: number;

  /**
   * The item separation distance in px.
   */
  itemSeparation?: number;

  /**
   * The area id.
   */
  areaId: string;

  /**
   * Callback when the an area was selected.
   * @param id The selected id.
   */
  onAreaSelected?: (id: string) => void;

  /**
   * Is selected.
   */
  isSelected?: boolean;
}

/**
 * Renders the Area List Item.
 */
export default function AreaListItem({
  itemWidth = RFValue(90),
  itemSeparation = RFValue(5),
  areaId,
  onAreaSelected,
  isSelected = false,
}: AreaListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: itemWidth,
      height: RFValue(30),
      marginRight: itemSeparation,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: RFValue(1),
      borderColor: 'white',
    },
    labelButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    labelContainer: {
      flex: 3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontSize: RFValue(9),
      lineHeight: RFValue(9),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '90%',
      textAlign: 'left',
      paddingLeft: RFValue(2),
    },
    countLabelContainer: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.BLACK(1),
      borderRightWidth: RFValue(1),
      borderRightColor: 'white',
    },
    countLabel: {
      fontSize: RFValue(10),
      lineHeight: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '90%',
      textAlign: 'center',
    },
  });

  const getAreaName = (): string =>
  {
    const area = areaRepository.getById(areaId);
    return area === undefined ? '' : area.name;
  };

  const selectAreaPressed = (): void =>
  {
    if (onAreaSelected !== undefined)
    {
      onAreaSelected(areaId);
    }
  };

  const getScreenCount = (): string =>
  {
    const area = areaRepository.getById(areaId);
    return area === undefined ? '0' : area.screens.length.toString();
  };

  return useObserver(() => (
    <View style={[styles.container, {
      backgroundColor: isSelected ? Colors.APP.teal : Colors.BLACK(0.3),
    }]}
    >
      <TouchableOpacity
        style={styles.labelButton}
        onPress={selectAreaPressed}
      >
        <View style={styles.labelContainer}>
          <Text numberOfLines={3} style={styles.label}>
            {getAreaName()}
          </Text>
        </View>

        <View style={styles.countLabelContainer}>
          <Text numberOfLines={1} style={styles.countLabel}>
            {getScreenCount()}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  ));
}
